import { useEffect, useState } from 'react'
import instance from '../_instance'
import dayjs from 'dayjs'
import { Backdrop, CircularProgress, TextField, Fab, Chip, Avatar } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

function AdminView() {
  const [status, setStatus] = useState('pending')
  const [players, setPlayers] = useState([])
  const [history, setHistory] = useState([])
  const [date, setDate] = useState(dayjs())

  useEffect(() => {
    ;(async () => {
      let response = await instance.get('/players')
      setPlayers(response.data)

      response = await instance.get('/history')
      setHistory(response.data)

      setStatus('resolved')
    })()
  }, [])

  const onButtonClick = async (subject, playerId) => {
    setStatus('fetching')
    let response = await instance.post('/poop', { subject, date: date.format('DD/MM/YYYY'), playerId })
    setHistory(response.data)
    setStatus('resolved')
  }

  return (
    <div className="admin-root">
      <h2 className="admin-title">💩😎 Mierda Ligi Admin 😎💩</h2>
      <DatePicker label="Fecha" value={date} onChange={setDate} renderInput={(params) => <TextField {...params} />} />
      {status !== 'pending' && (
        <>
          {Object.keys(players).map((p) => {
            let record = history.find((record) => record.d === date.format('DD/MM/YYYY'))
            let playerCount = record ? record[p] ?? 0 : 0
            return (
              <div key={p} className="admin-player">
                <Fab
                  disabled={playerCount === 0}
                  color="warning"
                  aria-label="add"
                  size="small"
                  onClick={() => onButtonClick('subtract', parseInt(p.replace('p', '')))}
                >
                  <RemoveIcon />
                </Fab>
                <span className="admin-player-name">{players[p]}</span>
                <Chip
                  variant="outlined"
                  label={playerCount}
                  color="info"
                  avatar={<Avatar>💩</Avatar>}
                  sx={{ fontWeight: 'bold', fontSize: 20 }}
                />
                <Fab color="success" aria-label="add" size="small" onClick={() => onButtonClick('add', parseInt(p.replace('p', '')))}>
                  <AddIcon />
                </Fab>
              </div>
            )
          })}
        </>
      )}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={status === 'pending' || status === 'fetching'}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}

export default AdminView
