import axios from 'axios'

const DEBUG_SHOW_DATA = false

const instance = axios.create({
  baseURL:
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? 'http://192.168.1.56:8787'
      : 'https://mierda-ligi-workers.cositas.workers.dev'
})

instance.interceptors.request.use(
  (req) => {
    if (process.env.NODE_ENV === 'development') {
      if (req.data && DEBUG_SHOW_DATA) {
        console.info(`HTTP --> ${req.method.toUpperCase()} ${req.url}`, req.data)
      } else {
        console.info(`HTTP --> ${req.method.toUpperCase()} ${req.url}`)
      }
    }
    return req
  },
  (error) => {
    console.error('HTTP --> REQUEST ERROR: ', error)
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (res) => {
    if (process.env.NODE_ENV === 'development') {
      if (DEBUG_SHOW_DATA) {
        console.info(`HTTP --> ${res.status} ${res.config.url}`, res.data)
      } else {
        console.info(`HTTP --> ${res.status} ${res.config.url}`)
      }
    }
    return res
  },
  (error) => {
    console.error('HTTP --> RESPONSE ERROR: ', error)
    return Promise.reject(error)
  }
)

export default instance
