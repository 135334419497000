import { Route, Routes } from 'react-router-dom'
import AdminView from './views/AdminView'
import HomeView from './views/HomeView'
import './App.css'

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomeView />} />
      <Route path="/admin-carlos" element={<AdminView />} />
    </Routes>
  )
}

export default App
