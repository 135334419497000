import { useEffect, useState } from 'react'
import instance from '../_instance'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Backdrop,
  CircularProgress,
  Typography
} from '@mui/material'
import Container from '@mui/material/Container'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie } from 'recharts'
import mobile from 'is-mobile'

function HomeView() {
  const [status, setStatus] = useState('pending')
  const [players, setPlayers] = useState([])
  const [totalRanking, setTotalRanking] = useState({})
  const [oneDayRankingData, setOneDayRankingData] = useState([])
  const [meanData, setMeanData] = useState([])
  const [daysWithoutPoopData, setDaysWithoutPoopData] = useState([])

  useEffect(() => {
    ;(async () => {
      let response = await instance.get('/players')
      setPlayers(response.data)

      response = await instance.get('/ranking/total')
      setTotalRanking(response.data)

      response = await instance.get('/ranking/one-day')
      setOneDayRankingData(response.data)

      response = await instance.get('/mean')
      setMeanData(response.data)

      response = await instance.get('/days-without-poop')
      setDaysWithoutPoopData(response.data)

      setStatus('resolved')
    })()
  }, [])

  return (
    <Container maxWidth="xl">
      <div className="home">
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#54b4d3', marginBottom: '30px', fontSize: '2rem' }}>
          💩😎 Mierda Ligi 😎💩
        </Typography>
        {status === 'resolved' && (
          <>
            <Paper style={{ width: 300 }} elevation={10}>
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '#37474F' }}>
                      <TableCell sx={{ fontWeight: 'bold' }}>Jugador</TableCell>
                      <TableCell align="right">💩</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(totalRanking).map((p) => (
                      <TableRow
                        key={p}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          '&:first-of-type th': { color: '#00E676' },
                          '&:nth-of-type(2) th': { color: '#69F0AE' },
                          '&:nth-last-of-type(-n+2) th': { color: '#ff9800' },
                          '&:last-child th': { color: '#f44336' }
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {players[p]}
                        </TableCell>
                        <TableCell align="right">{totalRanking[p]}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Typography variant="h4" sx={{ fontWeight: 'bold', marginTop: '50px', marginBottom: '30px' }}>
              Más 💩 en un día
            </Typography>
            <div style={{ width: mobile() ? 350 : 500, height: 300 }}>
              <ResponsiveContainer>
                <BarChart data={oneDayRankingData} layout="vertical">
                  <CartesianGrid strokeDasharray="2 5" />
                  <XAxis type="number" />
                  <YAxis dataKey="name" type="category" interval={0} width={80} padding={{ left: 50 }} />
                  <Bar dataKey="Total" fill="#54B4D3" />
                  <Tooltip labelStyle={{ color: '#121212' }} />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <Typography variant="h4" sx={{ fontWeight: 'bold', marginTop: '50px', marginBottom: '30px' }}>
              Media de 💩 diaria
            </Typography>
            <div style={{ width: mobile() ? 350 : 500, height: 300 }}>
              <ResponsiveContainer>
                <BarChart data={meanData} layout="vertical">
                  <CartesianGrid strokeDasharray="2 5" />
                  <XAxis type="number" />
                  <YAxis dataKey="name" type="category" interval={0} width={80} padding={{ left: 50 }} />
                  <Bar dataKey="Media" fill="#FFAB91" />
                  <Tooltip labelStyle={{ color: '#121212' }} />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <Typography variant="h4" sx={{ fontWeight: 'bold', marginTop: '50px', marginBottom: '30px' }}>
              Días seguidos sin 💩
            </Typography>
            <div style={{ width: mobile() ? 350 : 500, height: 300 }}>
              <ResponsiveContainer>
                <BarChart data={daysWithoutPoopData} layout="vertical">
                  <CartesianGrid strokeDasharray="2 5" />
                  <XAxis type="number" />
                  <YAxis dataKey="name" type="category" interval={0} width={80} padding={{ left: 50 }} />
                  <Bar dataKey="Dias" fill="#80CBC4" />
                  <Tooltip labelStyle={{ color: '#121212' }} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </>
        )}
        <p className="thanks">🖤 De Gamboa pa los chavales de Fuck Uni</p>
      </div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={status === 'pending' || status === 'fetching'}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  )
}

export default HomeView
